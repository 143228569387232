<template>
  <h2>Datos de Inversionistas</h2>

  <section v-if="data.length">
    <table>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Fecha de registro</th>
          <th>Fecha de inversion</th>
          <th>Monto</th>
          <th>Comisines</th>
          <th>MSI</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in data" :key="row.investments._id">
          <td>{{ row.fullname }}</td>
          <td>{{ row.email }}</td>
          <td>{{ formatDate(row.createdAt) }}</td>
          <td>{{ formatDate(row.investments.createdAt) }}</td>
          <td>{{ formatNumber(row.investments.amount) }}</td>
          <td>
            <template v-if="row.investments.fee">
              <div>
                Monto: {{ formatNumber(row.investments.fee.amount) }} {{ row.investments.fee.currency }}
              </div>
              <div>
                Impuesto: {{ formatNumber(row.investments.fee.tax) }} {{ row.investments.fee.currency }}
              </div>
            </template>
          </td>
          <td>{{ row.investments.payments }}</td>
        </tr>
      </tbody>
    </table>
  </section>
  <section class="empty-investments" v-else>
    <h3>Todavia no tienes inversiones </h3>
  </section>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import { ref } from 'vue';
import UserService from '../services/UserService';

export default {
  setup() {
    const data = ref({});
    const loading = ref(false);

    const fetchData = async () => {
      loading.value = true;
      try {
        data.value = await UserService.getInvestments();
        loading.value = false;
      } catch (error) {
        console.log(error);
        alert('Lo sentimos ha ocurrido un error');
        loading.value = false;
      }
    };

    fetchData();

    const formatDate = (date) => {
      if (!date) {
        return '-';
      }
      const instance = moment.utc(date);
      return instance.local().format('DD/MM/YYYY HH:mm');
    };

    const formatNumber = (number) => numeral(number).format('$0,0');

    return {
      data,
      loading,
      formatDate,
      formatNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/views/admin.scss";
</style>
